import React from 'react';
import RangeSlider from './RangeSlider';
import RangeInput, { RangeInputProps } from './RangeInput';

interface RangeSliderInputProps extends RangeInputProps {
  label?: string;
  step: number;
  min: number;
  max: number;
}

const RangeSliderInput: React.FC<RangeSliderInputProps> = ({
  label,
  minValue,
  maxValue,
  maxValuePlaceholder,
  minValuePlaceholder,
  onChange,
  step,
  min,
  max,
  variant = 'default',
}) => {
  return (
    <div className='space-y-1 w-full'>
      {!!label && <p className='font-primary-medium text-dark mb-3'>{label}</p>}
      <RangeInput
        minValue={minValue}
        maxValue={maxValue}
        onChange={onChange}
        maxValuePlaceholder={maxValuePlaceholder}
        minValuePlaceholder={minValuePlaceholder}
        variant={variant}
      />
      <div className='py-4 px-2'>
        <RangeSlider
          value={[+minValue, +maxValue]}
          min={min}
          max={max}
          step={step}
          onChange={(number) => {
            onChange(number[0].toString(), number[1].toString());
          }}
          variant={variant}
        />
      </div>
    </div>
  );
};

export default RangeSliderInput;
