// eslint-disable-next-line import/no-nodejs-modules
import { UrlObject } from 'url';
import { GoogleMap, InfoBox } from '@react-google-maps/api';
import Image from 'next/image';
import Link from 'next/link';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useGoogleMapsAPILoader } from '../hooks/useGoogleMapsAPILoader';

const containerStyle = {
  width: '100%',
  height: '100%',
};

interface PropertyLocationsMapProps {
  markers: {
    lat: number;
    lng: number;
    price?: number;
    href?: string | UrlObject;
  }[];
}

const PropertyLocationsMap: React.FC<PropertyLocationsMapProps> = ({
  markers,
}) => {
  const ref = useRef<GoogleMap>(null);
  const isLoaded = useGoogleMapsAPILoader();
  const [zoom, setZoom] = useState<number>(12);
  const [center, setCenter] = useState<google.maps.LatLng>();

  const onLoad = useCallback(() => {
    const bounds = new google.maps.LatLngBounds();

    markers.forEach((marker) => {
      bounds.extend({ lat: marker.lat, lng: marker.lng });
    });

    ref.current?.state.map.fitBounds(bounds);
    setCenter(bounds.getCenter());
    setZoom(ref.current?.state.map.getZoom());
  }, [markers]);

  useEffect(() => {
    if (isLoaded && ref.current.state.map) {
      onLoad();
    }
  }, [isLoaded, onLoad]);

  return (
    <div className='w-full h-full'>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          ref={ref}
          center={center}
          zoom={zoom}
          onLoad={onLoad}
          clickableIcons={false}
        >
          {markers.map((marker, index) => (
            <InfoBox
              options={{ closeBoxURL: '', enableEventPropagation: true }} //hides close icon on the marker.
              key={[marker.lat, marker.lng, marker.price, index].join()}
              position={marker as unknown as google.maps.LatLng}
            >
              <div className='relative pt-7 overflow-hidden'>
                <Link href={marker.href}>
                  <div className='absolute z-30 top-0'>
                    <Image
                      src='/icons/mapMarker.svg'
                      width={30}
                      height={30}
                      alt='mark'
                      layout='fixed'
                    />
                  </div>
                </Link>
                {marker.price && (
                  <Link href={marker.href}>
                    <div className='flex flex-col items-start ml-1 cursor-pointer'>
                      <div className='ml-1 -mb-0.5 border-solid border-b-primary border-b-8 border-x-transparent border-x-8 border-t-0' />
                      <div className='font-primary-regular whitespace-nowrap text-xs text-white pt-0.5 px-2 rounded-full block bg-primary'>
                        <span>
                          {`$${Intl.NumberFormat('en-US', {
                            notation: 'compact',
                            maximumFractionDigits: 1,
                          }).format(marker.price)}`}
                        </span>
                      </div>
                    </div>
                  </Link>
                )}
              </div>
            </InfoBox>
          ))}
        </GoogleMap>
      )}
    </div>
  );
};

export default React.memo(PropertyLocationsMap);
