import classNames from 'classnames';
import React from 'react';

export interface GeminiCheckboxInputProps {
  label: string;
  value: boolean;
  disabled?: boolean;
  onChange(value: boolean): void;
}

const GeminiCheckboxInput: React.FC<GeminiCheckboxInputProps> = ({
  label,
  value,
  disabled = false,
  onChange,
}) => {
  return (
    <div className='flex items-center relative h-5'>
      <label className='flex items-center'>
        <input
          type='checkbox'
          checked={value}
          disabled={disabled}
          onChange={(e) => onChange(e.target.checked)}
          className='hidden'
          aria-checked={value}
          aria-disabled={disabled}
        />
        <div className='absolute top-0 left-0 w-5 h-5 flex items-center justify-center ring-[1.5px] ring-cobalt p-[1.5px]'>
          <div
            className={classNames('h-full w-full', { 'bg-cobalt': value })}
          />
        </div>
        <span
          className={classNames(
            'ml-8 font-inter text-base text-cobalt transition-opacity duration-200 text-opacity-60',
            {
              'text-opacity-100': value,
            }
          )}
        >
          {label}
        </span>
      </label>
    </div>
  );
};

export default GeminiCheckboxInput;
