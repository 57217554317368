import React from 'react';
import CheckboxInput, { CheckboxInputProps } from './CheckboxInput';
import GeminiCheckboxInput from './Gemini/Inputs/GeminiCheckboxInput';

type GroupedCheckBoxInputsVariant = 'default' | 'gemini';

interface GroupedCheckBoxInputsProps {
  label?: string;
  options?: CheckboxInputProps[];
  variant?: GroupedCheckBoxInputsVariant;
}

const GroupedCheckBoxInputs: React.FC<GroupedCheckBoxInputsProps> = ({
  label,
  options,
  variant = 'default',
}) => {
  const oddItems = options.filter((_, index) => index % 2 === 0);
  const evenItems = options.filter((_, index) => index % 2 !== 0);

  const checkboxInputVariantMap: Record<
    GroupedCheckBoxInputsVariant,
    React.FC
  > = {
    default: CheckboxInput,
    gemini: GeminiCheckboxInput,
  };

  const CheckboxInputComponent = checkboxInputVariantMap[variant];

  return (
    <div>
      {!!label && <p className='font-primary-medium text-dark mb-3'>{label}</p>}
      <div className=' flex flex-row space-x-5'>
        <div className='flex-grow space-y-4'>
          {oddItems.map((option) => (
            <CheckboxInputComponent key={option.label} {...option} />
          ))}
        </div>
        <div className='flex-grow space-y-4'>
          {evenItems.map((option) => (
            <CheckboxInputComponent key={option.label} {...option} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default GroupedCheckBoxInputs;
