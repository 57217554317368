import React from 'react';
import { isCASiteOnly } from '../../utils/SiteConfiguration';

interface ListingPaginationProps {
  currentPage: number;
  totalPagesCount: number;
  onPageChange(page: number): void;
  hasNext: boolean;
  variant?: 'default' | 'gemini';
}

const ListingPagination: React.FC<ListingPaginationProps> = ({
  currentPage,
  totalPagesCount,
  onPageChange,
  hasNext,
  variant = 'default',
}) => {
  const isCASite = isCASiteOnly();
  const hasPrev = currentPage > 0;

  // Define styles for different variants
  const styles = {
    common: {
      default: 'uppercase text-sm font-primary-medium',
      gemini: 'font-telegraf text-cobalt text-base tracking-[0.32px]',
    },
    textButton: {
      default: `text-primary uppercase text-sm font-primary-medium`,
      gemini: 'font-telegraf text-cobalt text-base tracking-[0.32px]',
    },
    disabledText: {
      default: 'text-gray-300 uppercase text-sm font-primary-medium',
      gemini:
        'font-telegraf text-cobalt text-base tracking-[0.32px] text-opacity-60',
    },
    pageNumber: {
      default: 'text-lg text-dark text-center',
      gemini: 'font-telegraf text-cobalt text-base tracking-[0.32px]',
    },
  };

  // Determine the appropriate classes based on the variant
  const getVariantClass = (
    type: 'textButton' | 'disabledText' | 'pageNumber'
  ): string => styles[type][variant];

  // Render pagination button or text
  const renderPaginationControl = (
    type: 'prev' | 'next',
    isEnabled: boolean,
    handleClick: () => void
  ): React.ReactElement =>
    isEnabled ? (
      <button onClick={handleClick}>
        <span className={getVariantClass('textButton')}>
          {type === 'prev' ? 'Previous' : 'Next'}
        </span>
      </button>
    ) : (
      <p className={getVariantClass('disabledText')}>
        {type === 'prev' ? 'Previous' : 'Next'}
      </p>
    );

  return (
    <div className='w-full flex items-center justify-between py-10'>
      {renderPaginationControl('prev', hasPrev, () =>
        onPageChange(currentPage - 1)
      )}
      <div className={getVariantClass('pageNumber')}>
        {/* Artemis page starts from 0 and Wanderer page starts from 1 */}
        {isCASite
          ? `${currentPage + 1}/${totalPagesCount}`
          : `${currentPage + 1}/${totalPagesCount + 1}`}
      </div>
      {renderPaginationControl('next', hasNext, () =>
        onPageChange(currentPage + 1)
      )}
    </div>
  );
};

export default ListingPagination;
