import { Range } from 'rc-slider/lib';
import 'rc-slider/assets/index.css';

type RangeSliderVariant = 'default' | 'gemini';

export interface RangeSliderProps {
  value: number[];
  min: number;
  max: number;
  step: number;
  onChange(value: number[]): void;
  disabled?: boolean;
  variant?: RangeSliderVariant;
}

const sliderColorVariantMap: Record<RangeSliderVariant, string> = {
  default: '#05C3F9',
  gemini: '#263993',
};

const RangeSlider: React.FC<RangeSliderProps> = ({
  value,
  min,
  max,
  step,
  onChange,
  disabled = false,
  variant = 'default',
}) => {
  const isGeminiVariant = variant === 'gemini';
  const trackColor = disabled ? '#D6D6D6' : sliderColorVariantMap[variant];
  const handleColor = isGeminiVariant ? '#263993' : '#FFF';
  const railColor = disabled ? '#D6D6D6' : '#848482';

  return (
    <Range
      value={value}
      min={min}
      max={max}
      step={step}
      onChange={onChange}
      disabled={disabled}
      allowCross={false}
      style={{ backgroundColor: 'transparent' }}
      trackStyle={[
        {
          backgroundColor: trackColor,
          height: 4,
          borderRadius: 15,
          opacity: 0.8,
        },
      ]}
      handleStyle={[
        {
          borderColor: trackColor,
          borderWidth: 3,
          top: 3,
          left: 8,
          height: 15,
          width: 15,
          backgroundColor: handleColor,
          boxShadow:
            '0px 4px 2px rgba(1, 62, 80, 0.12), 0px 2px 2px rgba(1, 62, 80, 0.24)',
        },
        {
          borderColor: trackColor,
          borderWidth: 3,
          top: 3,
          left: 8,
          height: 15,
          width: 15,
          backgroundColor: handleColor,
          boxShadow:
            '0px 4px 2px rgba(1, 62, 80, 0.12), 0px 2px 2px rgba(1, 62, 80, 0.24)',
        },
      ]}
      railStyle={{
        backgroundColor: railColor,
        opacity: 0.2,
        height: 4,
        borderRadius: 10,
      }}
    />
  );
};

export default RangeSlider;
