import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/sharp-solid-svg-icons';
import classNames from 'classnames';
import React from 'react';

export type ListingButtonAccentColor = 'primary' | 'warning';

type ListingButtonVariant = 'default' | 'gemini';

export interface ListingButtonProps {
  label: string;
  hideArrow?: boolean;
  accentColor?: ListingButtonAccentColor;
  active?: boolean;
  onClick?(): void;
  variant?: ListingButtonVariant;
}

const ListingButton: React.FC<ListingButtonProps> = ({
  label,
  accentColor,
  hideArrow,
  active = false,
  onClick,
  variant = 'default',
}) => {
  const accentColorMap: Record<ListingButtonAccentColor, string> = {
    primary: 'bg-primary',
    warning: 'bg-warning',
  };

  const buttonClassNameMap: Record<ListingButtonVariant, string> = {
    default: classNames(
      'border rounded-md flex items-center py-2 px-2 md:p-3 space-x-2',
      { 'bg-dark bg-opacity-5': active }
    ),
    gemini:
      'w-[calc(100vw-40px)] md:w-auto md:min-w-[155px] flex items-center border-[1.5px] border-cobalt bg-off-white px-[14px] py-[7px] font-inter text-base text-cobalt shrink-0 space-x-7',
  };

  return (
    <button className={buttonClassNameMap[variant]} onClick={onClick}>
      {!!accentColor && (
        <div
          className={classNames(
            'w-2 h-2 rounded-full',
            accentColorMap[accentColor]
          )}
        />
      )}
      <span className='whitespace-nowrap flex-grow truncate text-left'>
        {label}
      </span>
      {!hideArrow && (
        <FontAwesomeIcon icon={faChevronDown} className='text-sm' />
      )}
    </button>
  );
};

export default ListingButton;
