import classNames from 'classnames';
import React from 'react';
import CurrencyInput from 'react-currency-input-field';

type CurrencyInputVariantType = 'default' | 'gemini';

export interface RangeInputProps {
  minValue: string;
  maxValue: string;
  minValuePlaceholder: string;
  maxValuePlaceholder: string;
  onChange(minValue: string, maxValue: string): void;
  variant?: CurrencyInputVariantType;
}

const RangeInput: React.FC<RangeInputProps> = ({
  minValue,
  maxValue,
  minValuePlaceholder,
  maxValuePlaceholder,
  onChange,
  variant = 'default',
}) => {
  const currencyInputClassMap: Record<CurrencyInputVariantType, string> = {
    default: 'p-2.5 border-gray-200 rounded-md bg-white w-40',
    gemini:
      'border-[1.5px] border-light-secondary-gray-2 px-1.5 py-1 font-inter text-base text-cobalt w-32',
  };

  return (
    <div className='flex flex-row items-center justify-between space-x-3.5'>
      <CurrencyInput
        value={minValue}
        placeholder={minValuePlaceholder}
        onValueChange={(selectedValue) => {
          // selectedValue should not be greater than maxValue
          if (parseInt(selectedValue) > parseInt(maxValue)) {
            selectedValue = maxValue;
          }
          onChange(selectedValue, maxValue);
        }}
        className={classNames(
          'appearance-none focus:outline-none focus:ring-0',
          currencyInputClassMap[variant]
        )}
        allowDecimals={false}
        prefix='$ '
        allowNegativeValue={false}
      />
      <div className='bg-gray-400 w-2.5 h-px rounded-full' />
      <CurrencyInput
        value={maxValue}
        placeholder={maxValuePlaceholder}
        onValueChange={(selectedValue) => {
          // selectedValue should not be less than minValue
          if (parseInt(selectedValue) < parseInt(minValue)) {
            selectedValue = minValue;
          }
          onChange(minValue, selectedValue);
        }}
        className={classNames(
          'appearance-none focus:outline-none focus:ring-0',
          currencyInputClassMap[variant]
        )}
        allowDecimals={false}
        allowNegativeValue={false}
        prefix='$ '
      />
    </div>
  );
};

export default RangeInput;
