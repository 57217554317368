import {
  PropertyFilterResponseBathroomsEnum,
  PropertyFilterResponseBedroomsEnum,
} from '../openapi/bff';
import { ISelectOption } from '../components/controlled-inputs/ControlledSelectInputV7';

export const BEDS_SELECT_OPTION: ISelectOption[] = [
  {
    label: 'Any',
    value: PropertyFilterResponseBedroomsEnum.Any,
  },
  {
    label: '1+',
    value: PropertyFilterResponseBedroomsEnum.OneOrMore,
  },
  {
    label: '2+',
    value: PropertyFilterResponseBedroomsEnum.TwoOrMore,
  },
  {
    label: '3+',
    value: PropertyFilterResponseBedroomsEnum.ThreeOrMore,
  },
  {
    label: '4+',
    value: PropertyFilterResponseBedroomsEnum.FourOrMore,
  },
  {
    label: '5+',
    value: PropertyFilterResponseBedroomsEnum.FiveOrMore,
  },
];

export const BATHS_SELECT_OPTION: ISelectOption[] = [
  {
    label: 'Any',
    value: PropertyFilterResponseBathroomsEnum.Any,
  },
  {
    label: '1+',
    value: PropertyFilterResponseBathroomsEnum.OneOrMore,
  },
  {
    label: '2+',
    value: PropertyFilterResponseBathroomsEnum.TwoOrMore,
  },
  {
    label: '3+',
    value: PropertyFilterResponseBathroomsEnum.ThreeOrMore,
  },
  {
    label: '4+',
    value: PropertyFilterResponseBathroomsEnum.FourOrMore,
  },
  {
    label: '5+',
    value: PropertyFilterResponseBathroomsEnum.FiveOrMore,
  },
];
