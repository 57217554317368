import React from 'react';

export interface CheckboxInputProps {
  label: string;
  value: boolean;
  disabled?: boolean;
  onChange(value: boolean): void;
}

const CheckboxInput: React.FC<CheckboxInputProps> = ({
  value,
  onChange,
  disabled,
  label,
}) => {
  return (
    <div>
      <label>
        <input
          type='checkbox'
          checked={value}
          disabled={disabled}
          onChange={(e) => onChange(e.target.checked)}
          className='rounded border-none ring-1 ring-gray-200 focus:outline-none focus:ring-0 h-5 w-5 text-primary'
        />
        <span className='ml-3 font-primary-light leading-4 text-dark'>
          {label}
        </span>
      </label>
    </div>
  );
};

export default CheckboxInput;
