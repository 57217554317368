import classNames from 'classnames';
import React, { useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { EnumMap } from '../types';

type SimpleDropDownVariant = 'default' | 'gemini';

interface SimpleDropDownProps {
  buttonComponent: React.ReactElement;
  children(closeDropDown: () => void): any;
  variant?: SimpleDropDownVariant;
}

const SimpleDropDown: React.FC<SimpleDropDownProps> = ({
  buttonComponent,
  children,
  variant = 'default',
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const containerClassNameMap: EnumMap<SimpleDropDownVariant, string> = {
    default: 'border border-gray-50 shadow-md mt-2 py-6 px-8 rounded-lg',
    gemini:
      'w-[calc(100vw-40px)] md:w-auto flex items-center p-[14px] shadow-md',
  };

  return (
    <Popover
      isOpen={isOpen}
      positions={['bottom']}
      align='end'
      onClickOutside={() => setIsOpen(false)}
      containerClassName='z-0'
      content={
        <div className={classNames('bg-white', containerClassNameMap[variant])}>
          {children(() => setIsOpen(false))}
        </div>
      }
    >
      <div onClick={() => setIsOpen(!isOpen)}>{buttonComponent}</div>
    </Popover>
  );
};

export default SimpleDropDown;
